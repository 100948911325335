// /**
//  * clone
//  *
//  * return a clone of the object passed in parameters
//  *
//  * @param  {Object} obj
//  * @return {Object}
//  */
// export function clone(obj) {
//   // Handle the 3 simple types, and null or undefined
//   if (null == obj || typeof obj !== "object") return obj;

import { CHAT_COLORS, USERS } from "@/constants";
import _ from "lodash";

//   let copy;

//   // Handle Date
//   if (obj instanceof Array) {
//     copy = [];
//     for (let i = 0; i < obj.length; i++) {
//       copy[i] = clone(obj[i]);
//     }
//   } else if (obj instanceof Object) {
//     copy = {};
//     for (let attr in obj) {
//       if (obj.hasOwnProperty(attr)) copy[attr] = clone(obj[attr]);
//     }
//   } else {
//     throw new Error("Unable to copy obj! Its type isn't supported.");
//   }

//   return copy;
// }

/**
 * removeDuplicate
 * remove duplicate values from array
 * @param {Array} arrays - array
 * @returns {Array}
 */
export function removeDuplicate(arr) {
  if (Array.isArray(arr)) {
    return arr.filter((item, pos, self) => self.indexOf(item) === pos);
  } else {
    return [];
  }
}

/**
 * debounce
 *
 * Returns a function, that, as long as it continues to be invoked, will not be triggered.
 *
 * @param  {function} func
 * @param  {number} delay
 * @return {function}
 */
export function debounce(func, delay) {
  var inDebounce = undefined;
  return function() {
    var context = this;
    var args = arguments;
    clearTimeout(inDebounce);
    return (inDebounce = setTimeout(function() {
      return func.apply(context, args);
    }, delay));
  };
}

/**
 * throttle
 *
 * Returns a function, that, as long as it continues to be invoked,
 * isn't executed more than once every N=limt miliseconds.
 *
 * @param  {function} func
 * @param  {number} limit
 * @return {function}
 */
export function throttle(func, limit) {
  var inThrottle = undefined;
  return function() {
    var args = arguments,
      context = this;
    if (!inThrottle) {
      func.apply(context, args);
      inThrottle = true;
      return setTimeout(function() {
        return (inThrottle = false);
      }, limit);
    }
  };
}

/**
 * dragAndDropSupport
 *
 * check if browser support drag and drop file upload
 *
 * @return {Boolean}
 */
export function dragAndDropSupport() {
  const div = document.createElement("div");
  return (
    ("draggable" in div || ("ondragstart" in div && "ondrop" in div)) &&
    "FormData" in window &&
    "FileReader" in window
  );
}

/**
 * createBlobFromBinary
 *
 * Create a blob from binary and mimetype
 *
 * @param  {Binary} binary
 * @param  {String} mimeType (Ex: 'application/pdf')
 * @return {Blob}
 */
export function createBlobFromBinary(binary, mimeType) {
  const binaryLength = binary.length;
  const binaryArray = new Uint8Array(binaryLength);
  for (let i = 0; i < binaryLength; i++) {
    binaryArray[i] = binary.charCodeAt(i);
  }
  return new Blob([binaryArray], {
    type: mimeType,
  });
}

/**
 * blobToDataURL
 *
 * return the dataUrl of a blob in the first parameter of the callback
 *
 * @param  {Blob} blob
 * @param  {Function} callback
 * Callback will have the dataUrl in its first parameter
 */
export function blobToDataURL(blob, callback) {
  const fileReader = new FileReader();
  fileReader.readAsDataURL(blob);
  fileReader.onload = function(e) {
    callback(e.target.result);
  };
}

/**
 * getFileExtension
 *
 * return the extension of a fileName
 * Ex: 'image.png' will return 'png'
 *
 * @param  {String} filename
 * @return {String}
 */
export function getFileExtension(fileName) {
  return fileName.slice(((fileName.lastIndexOf(".") - 1) >>> 0) + 2);
}

/**
 * @description determine if an array contains one or more items from another array.
 * @param {array} haystack the array to search.
 * @param {array} arr the array providing items to check for in the haystack.
 * @return {boolean} true|false if haystack contains at least one item from arr.
 */
export function findOne(haystack = [], arr = []) {
  return arr.some(v => haystack.includes(v));
}

export function getKeyByValue(object, value) {
  return Object.keys(object).find(key => object[key] === value);
}

export const sortByKey = (dataArray, key) => {
  const newData = dataArray.sort((a, b) => {
    if (a[key].toLowerCase() > b[key].toLowerCase()) {
      return 1;
    }
    if (a[key].toLowerCase() < b[key].toLowerCase()) {
      return -1;
    }
    return 0;
  });
  return newData;
};

export const createFullNameAuthor = user => {
  user.fullName = `${user.author.firstName} ${user.author.lastName}`;

  return user;
};

export const getUserFullName = user => {
  return `${user.firstname} ${user.lastname}`;
};

export const adaptUser = user => {
  user.fullName = getUserFullName(user);

  return user;
};

export const adaptReport = report => {
  report.authorName = getUserFullName(report.author);
  report.total = report.tva + report.amount;

  return report;
};

export const getInitialsUser = user => {
  user.initials = `${user.firstname[0]}${user.lastname[0]}`;

  return user;
};

export const stringToHslColor = str => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  const h = Math.abs(hash % 53);
  return CHAT_COLORS[h];
};

export const getUserMapByScope = (userArray, usersObject) => {
  const scope = [USERS.COLLAB, USERS.ADMIN, USERS.CLIENT];
  userArray.forEach(user => {
    if (scope.indexOf(user.scope)) {
      usersObject[user.id] = {
        ...usersObject,
        initials: `${user.firstname[0]}${user.lastname[0]}`,
        firstname: `${user.firstname}`,
        lastname: `${user.lastname}`,
      };
    }
  });
};

export const filterEmployeesCompanyId = (employees, companyId) =>
  employees
    .filter(employee => employee.company_id === companyId)
    .map(employee => ({
      text: `${employee.firstName} ${employee.lastName}`,
      value: employee,
    }));

export const createFileObject = (object, file, fileName) => {
  const newfile = new Blob([file]);
  newfile.name = fileName;
  object.file = newfile;
};

export const convertToTime = time => {
  if (time) {
    return new Date(time);
  } else return null;
};

export const compareObjects = (a, b) => {
  return _.isEqual(a, b);
};

/**
 * Find Item A has but B doesn't
 * @param {Array} a - Array of Objects
 * @param {Array} b - Array of Objects
 * @param {String} property - Property to filter default: "id"
 * @returns {Array}
 */

export const filterTwoArray = (a, b, property = "id") => {
  return a.filter(item => {
    return (
      !b.find(element => {
        return item[property] === element[property];
      }) !== undefined
    );
  });
};

export const credit = ({ montant, taux, duree }) => {
  //? montant: Money you want to borrow
  //? taux: Tax by year
  //? duree: In how long you wanna pay (by year)
  //? mensualite: Money you have to pay in a month
  let calculatedTax = taux / 100;

  const upperM = (montant * calculatedTax) / 12;

  const downM = 1 - Math.pow(1 + calculatedTax / 12, duree * 12 * -1);

  const mensualite = upperM / downM;

  const cout = 12 * duree * mensualite - montant;

  return {
    cout: cout,
    mensualite: mensualite,
  };
};

/**
 * Return is the number in range?
 * @param {Number} number - Number
 * @param {Number} start - Number
 * @param {Number} end - Number
 * @returns {Boolean} Return is the number in range?
 */

export const isBetween = (number, start, end) => {
  if (end == null) {
    return start <= number;
  }
  if (start == null) {
    return number <= end;
  }
  if (!end && !start) {
    console.log("There aren't end nor start");
  }
  return start <= number && number <= end;
};
