import usersModel from "@/model/users";
import companiesModel from "@/model/companies";
import Vue from "vue";
import { i18n } from "@/main";
import { USERS } from "@/constants";
import { get } from "lodash";

export const LIST_TRASH_USERS = "listTrashUsers";
export const LIST_UNVERIFIED_USERS = "listUnverifiedUsers";
export const LIST_USERS = "listUsers";

const actions = {
  fetchList: async ({ commit }, list) => {
    switch (list) {
      case LIST_USERS: {
        const items = await usersModel.getAll();
        commit("changeList", { list: list, items: items });
        break;
      }
      case LIST_UNVERIFIED_USERS: {
        const items = await usersModel.getAllUnverifiedUsers();
        commit("changeList", { list: list, items: items });
        break;
      }
      case LIST_TRASH_USERS: {
        const items = await usersModel.getTrashed();
        commit("changeList", { list: list, items: items });
        break;
      }
    }
  },

  trashItem: ({ commit }, list) => {
    const trashUser = async (item) => {
      try {
        await usersModel.putInTrash(item.id);

        Vue.toasted.global.AppSucces({
          message: "L'utilisateur '" + item.fullName + "' a bien été supprimé",
        });
        commit("deleteItem", { list: "listUsers", item: item });
      } catch (error) {
        console.log(error);
        Vue.toasted.global.AppError({
          message: error.msg,
        });
      }
    };
    list.forEach((item) => trashUser(item));
  },

  deleteVerifyItem: ({ commit }, list) => {
    const deleteUnVerified = async (item) => {
      try {
        await usersModel.removeUnverifiedUser(item.id);

        Vue.toasted.global.AppSucces({
          message: i18n.t("approve-user.delete-message", {
            firstName: item.fullName,
          }),
        });
        commit("deleteItem", { list: "listUnverifiedUsers", item: item });
      } catch (error) {
        console.log(error);
        Vue.toasted.global.AppError({
          message: error.msg,
        });
      }
    };
    list.forEach((item) => deleteUnVerified(item));
  },

  restoreItem: ({ commit }, list) => {
    const restore = async (item) => {
      try {
        await usersModel.restore(item.id);

        Vue.toasted.global.AppSucces({
          message: i18n.t("employee.restore-success", {
            firstName: item.fullName,
          }),
        });
        commit("deleteItem", { list: "listTrashUsers", item: item });
        commit("addItem", { list: "listUsers", item: item });
      } catch (error) {
        console.log(error);
        Vue.toasted.global.AppError({
          message: error.msg,
        });
      }
    };
    list.forEach((item) => restore(item));
  },

  deleteItem: ({ commit, rootGetters }, list) => {
    const deleteUser = async (item) => {
      try {
        if (rootGetters.isJEPA && item.scope === USERS.CLIENT) {
          const fullUserInfo = await usersModel.get(item.id);
          const companyId = get(fullUserInfo, "companies[0].id", null);
          await companiesModel.remove(companyId);
        }
        await usersModel.remove(item.id);

        Vue.toasted.global.AppSucces({
          message: i18n.t("user.delete-success", {
            firstName: item.fullName,
          }),
        });
      } catch (error) {
        console.log(error);
        Vue.toasted.global.AppError({
          message: error.msg,
        });
      }
      commit("deleteItem", { list: "listTrashUsers", item: item });
    };
    [list.forEach((item) => deleteUser(item))];
  },
};

export default actions;
