import fetch from "../services/fetch";
import store from "../store";
import { USERS } from "@/constants";
import companiesModel from "@/model/companies";

export default {
  get,
  getAll,
  getAllUnverifiedUsers,
  getUnverifiedUsers,
  getCollabs,
  getOthers,

  create,
  update,
  remove,
  removeUnverifiedUser,
  putInTrash,

  updateCollabRights,
  removeCollabRights,

  addCompany,
  removeCompany,

  restore,
  getTrashed,

  changePassword,
  setPassword,

  getOrganizations,
  selectOrganization,
  getUsersInOrganization,
  socketSync,
  socketDeSync,
  // addToken,
  // removeToken,
};

const createUser = payload => {
  return fetch("api/users", {
    method: "post",
    headers: {
      Authorization: store.state.token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
};

function get(id) {
  return fetch("api/users/" + id, {
    method: "get",
    headers: { Authorization: store.state.token },
  });
}

function getAll(params) {
  return fetch("api/users", {
    method: "get",
    headers: { Authorization: store.state.token },
    params: params,
  });
}

function getAllUnverifiedUsers() {
  return fetch("api/users/get-unverified-users", {
    method: "get",
    headers: { Authorization: store.state.token },
  });
}

function getUnverifiedUsers(id) {
  return fetch("api/users/get-unverified-users/" + id, {
    method: "get",
    headers: { Authorization: store.state.token },
  });
}

function getTrashed() {
  return fetch("api/users/get-trashed", {
    method: "post",
    headers: { Authorization: store.state.token },
  });
}

function getCollabs() {
  return fetch("api/users/get-collabs/", {
    method: "post",
    headers: { Authorization: store.state.token },
  });
}

function getOthers(id) {
  return fetch("api/users/get-others/" + id, {
    method: "post",
    headers: { Authorization: store.state.token },
  });
}

function makeid(length) {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

async function create(payload) {
  if (store.getters.isJEPA && payload.scope === USERS.CLIENT) {
    const { company } = await companiesModel.create({
      siren: makeid(9),
      name: `${payload.lastname} ${payload.firstname}`,
      codepostal: payload.codepostal,
      ville: payload.ville,
      firstName: payload.firstname,
      lastName: payload.lastname,
      createUser: false,
      folder_preset: 2,
    });

    payload.company_ids = [company.id];

    try {
      const user = await createUser(payload);
      return user;
    } catch (err) {
      console.log(`Error create user JEPA, deleting ${company.id}`);
      await companiesModel.remove(company.id);
      throw err;
    }
  } else {
    return createUser(payload);
  }
}

function update(user) {
  return fetch("api/users/" + (user.user_id ? user.user_id : user.id), {
    method: "put",
    headers: {
      Authorization: store.state.token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(user),
  });
}

function changePassword(form) {
  return fetch("api/users/change-password", {
    method: "post",
    headers: {
      Authorization: store.state.token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(form),
  });
}

function setPassword(id, newPassword) {
  return fetch("api/users/set-password/" + id, {
    method: "post",
    headers: {
      Authorization: store.state.token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      newPassword,
    }),
  });
}

/* Met le flag "is_trashed" de l'utilisateur à 1 */
function putInTrash(id) {
  return fetch("api/users/put-in-trash", {
    method: "post",
    headers: {
      Authorization: store.state.token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ id: id }),
  });
}

function restore(id) {
  return fetch("api/users/restore", {
    method: "post",
    headers: {
      Authorization: store.state.token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ id: id }),
  });
}

function remove(id) {
  return fetch("api/users/" + id, {
    method: "delete",
    headers: { Authorization: store.state.token },
  });
}

function removeUnverifiedUser(id) {
  return fetch("api/users/remove-unverified-user/" + id, {
    method: "delete",
    headers: { Authorization: store.state.token },
  });
}

function updateCollabRights(user, companyID) {
  return fetch("api/users/update-collab-rights", {
    method: "post",
    headers: {
      Authorization: store.state.token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      companyID: companyID,
      userID: user.id,
      canWriteCloud: user.can_write_in_cloud,
      canWriteInfos: user.can_modify_infos,
      canWriteChat: user.can_speak_in_chat,
    }),
  });
}

function removeCollabRights(userID, companyID) {
  return fetch("api/users/remove-collab-rights", {
    method: "post",
    headers: {
      Authorization: store.state.token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      companyID: companyID,
      userID: userID,
    }),
  });
}

function addCompany(
  userID,
  companyID,
  hasWebAccess = true,
  can_write_in_cloud,
  can_modify_infos,
  can_speak_in_chat
) {
  return fetch("api/users/add-company", {
    method: "post",
    headers: {
      Authorization: store.state.token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      companyID,
      userID,
      hasWebAccess,
      can_write_in_cloud,
      can_modify_infos,
      can_speak_in_chat,
    }),
  });
}

function removeCompany(userID, companyID) {
  return fetch("api/users/remove-company", {
    method: "post",
    headers: {
      Authorization: store.state.token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      companyID: companyID,
      userID: userID,
    }),
  });
}

function getOrganizations(email) {
  return fetch("api/users/get-organizations", {
    method: "post",
    headers: {
      Authorization: store.state.token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      email: email,
    }),
  });
}

function selectOrganization(email, orgaID) {
  return fetch("api/users/select-organization", {
    method: "post",
    headers: {
      Authorization: store.state.token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      email: email,
      organizationId: orgaID,
    }),
  });
}

function getUsersInOrganization(params) {
  return fetch("api/users/get-users-in-organizations", {
    method: "get",
    headers: { Authorization: store.state.token },
    params: params,
  });
}

function socketSync(socketId) {
  return fetch("api/users/socket-sync", {
    method: "post",
    headers: {
      Authorization: store.state.token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      socketId,
    }),
  });
}

function socketDeSync(socketId) {
  return fetch("api/users/socket-desync", {
    method: "post",
    headers: {
      Authorization: store.state.token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      socketId,
    }),
  });
}

// function addToken(token) {
// 	return fetch(`api/users/push-token/add`, {
// 		method: 'put',
// 		headers: {
// 			'Authorization': store.state.token,
// 			'Content-Type': 'application/json'
// 		},
// 		body: JSON.stringify({
// 			pushToken: token,
// 		})
// 	});

// }

// function removeToken(token) {
// 	return fetch(`api/users/push-token/remove`, {
// 		method: 'put',
// 		headers: {
// 			'Authorization': store.state.token,
// 			'Content-Type': 'application/json'
// 		},
// 		body: JSON.stringify({
// 			pushToken: token,
// 		})
// 	});
// }
