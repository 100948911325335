<!-- Affiche les éléments de {{items}} dans un tableau qu'on peut trier -->
<template>
  <div :class="sortableClass" class="sortable-list">
    <!-- eslint-disable vue/no-mutating-props -->
    <md-table
      v-if="items.length !== 0"
      ref="table"
      v-model="items"
      :class="sortableListTableClass"
      :md-card="isCard"
      :md-sort="defaultSort"
      :md-sort-order="defaultSortOrder"
      class="sortable-list__table"
      @md-selected="onSelect"
    >
      <!-- Titre -->
      <md-table-toolbar v-if="title">
        <p class="md-title">
          {{ title }}
        </p>
      </md-table-toolbar>

      <!-- Le corps du tableau-->
      <md-table-row
        slot="md-table-row"
        slot-scope="{ item }"
        :class="bindClass(hasPagination, item)"
        :md-auto-select="!Boolean(link)"
        :md-disabled="Boolean(item.is_protected || bypassSelectable)"
        :md-selectable="selectable"
        @click="goTo(item)"
      >
        <md-table-cell
          v-for="(parameter, index) in parameters"
          :key="index"
          :class="[parameter.class]"
          :md-label="parameter.name"
          :md-sort-by="
            !parameter.isIcon && !parameter.isMenu ? parameter.key : null
          "
          :style="{ width: parameter.width }"
          @click.stop="goTo(item, parameter)"
        >
          <!-- In case display icon -->
          <template v-if="parameter.isIcon || parameter.hasIcon">
            <!-- clickable ? -->
            <md-button
              v-if="parameter.clickable && parameter.iconFormat(item) !== ''"
              class="md-icon-button"
              @click.stop="goTo(item, parameter)"
            >
              <MeepIconDelete
                v-if="parameter.iconFormat(item) === 'delete'"
                class="app-icon"
              />

              <MeepIconRestore
                v-if="parameter.iconFormat(item) === 'restore'"
                class="app-icon"
              />

              <MeepIconAttachment
                v-if="parameter.iconFormat(item) === 'attach_file'"
                class="app-icon"
              />

              <MeepIconLock
                v-if="parameter.iconFormat(item) === 'lock'"
                class="app-icon"
              />
            </md-button>
            <component
              :is="$$filters.getIconItem(parameter.iconFormat(item))"
              v-else-if="
                $$filters.getIconItem(parameter.iconFormat(item)) &&
                parameter.iconFormat(item) !== ''
              "
              class="app-icon-big"
            />

            <md-icon v-else :class="parameter.iconClass(item)">
              {{ parameter.iconFormat(item) }}
            </md-icon>
          </template>

          <!-- Not contain icon -->
          <div v-else>
            <img
              v-if="parameter.isImage && item[parameter.key]"
              :src="item[parameter.key]"
              alt=""
              class="sortable-list__image"
            />

            <div v-else-if="parameter.format">
              {{ parameter.format(item[parameter.key], item) }}
            </div>

            <!-- Special status column -->
            <template v-else-if="parameter.status">
              <template v-if="!isDirectory(item)">
                <!-- Show nothing if current file dont have an accounting status -->
                <div v-if="item[parameter.key] === true">
                  <span class="label success">Traité</span>
                </div>
              </template>
            </template>

            <template v-else-if="parameter.checkbox === true">
              <md-checkbox
                v-model="item[parameter.key]"
                @change="parameter.checkBoxAction(item)"
              >
              </md-checkbox>
            </template>

            <md-menu
              v-else-if="parameter.isMenu && parameter.showMenu(item)"
              class="sortable-list__menu"
              md-align-trigger
              md-direction="bottom-end"
              md-size="small"
              @md-closed="$emit('menu-close')"
            >
              <md-button
                class="md-icon-button meep-icon-button"
                md-menu-trigger
                @click.stop=""
              >
                <MeepIconCloudEllipsis class="app-icon-small" />
              </md-button>

              <md-menu-content>
                <md-menu-item
                  v-for="(menuItem, menuIndex) in parameter.menuItems"
                  :key="menuIndex"
                  @click="
                    $emit('menu-click', {
                      actionName: menuItem.actionName,
                      item: item,
                    })
                  "
                >
                  <span class="sortable-list__menu-text">
                    {{ menuItem.label(item) }}
                  </span>
                </md-menu-item>
              </md-menu-content>
            </md-menu>

            <div v-else>
              {{ getItemValue(item, parameter.key) }}
            </div>
          </div>
        </md-table-cell>
      </md-table-row>
    </md-table>

    <NoItem
      v-else
      :title="notFoundTitle"
      :buttonText="notFoundText"
      :clickText="notFoundClickText"
      :isCard="true"
      :onClick="notFoundClick"
      :text="notFoundLabel"
    />
    <!-- Pagination -->
    <paginate
      v-if="hasPagination && pageCount > 1"
      v-model="page"
      :container-class="'table-pagination'"
      :next-link-class="'pagination md-button md-icon-button md-table-pagination-next md-theme-default'"
      :next-text="''"
      :no-li-surround="true"
      :page-count="pageCount"
      :page-link-class="'pagination md-button md-icon-button md-theme-default'"
      :prev-link-class="'pagination md-button md-icon-button md-table-pagination-previous md-theme-default'"
      :prev-text="''"
    />

    <md-snackbar
      :md-active="showSnackbar"
      :md-duration="Infinity"
      md-persistent
      md-position="center"
    >
      <span
        >Télécharger l'application
        <a :href="iOsUrl" target="_blank">iOS</a>
        ou
        <a :href="androidUrl" target="_blank">Android</a>
      </span>
      <md-button
        class="md-button md-primary--inverted-outline"
        @click="CLOSE_SNACKBAR"
      >
        Fermer
      </md-button>
    </md-snackbar>
  </div>
</template>

<script>
import config from "../config";
import Paginate from "vuejs-paginate";
import { get } from "lodash";
import { mapActions, mapGetters } from "vuex";
import MeepIconDelete from "./icons/MeepIconDelete.vue";
import MeepIconRestore from "./icons/MeepIconRestore.vue";
import MeepIconAttachment from "./icons/MeepIconAttachment.vue";
import MeepIconCloudEllipsis from "./icons/MeepIconCloudEllipsis.vue";
import MeepIconLock from "./icons/MeepIconLock.vue";
import NoItem from "@/components/NoItem.vue";

export default {
  name: "SortableList",

  components: {
    Paginate,
    MeepIconDelete,
    MeepIconRestore,
    MeepIconAttachment,
    MeepIconCloudEllipsis,
    MeepIconLock,
    NoItem,
  },

  props: {
    isCard: {
      type: Boolean,
      default: true,
    },
    /* single ou multiple */
    selectable: {
      type: String,
      required: false,
      default: "single",
    },
    /* Le champ par le quel trier par défaut */
    defaultSort: {
      type: String,
      required: false,
      default: "created_at",
    },
    /* Si le tri est ascendant ou descendant par défaut */
    defaultSortOrder: {
      type: String,
      required: false,
      default: "asc",
    },
    /* La base du lien vers lequel un élément va rediriger */
    link: {
      type: String,
      default: "",
    },
    /* */
    title: {
      type: String,
      default: "",
    },
    notFoundTitle: {
      type: String,
      required: false,
      default: "",
    },
    /* */
    notFoundLabel: {
      type: String,
      required: false,
      default: "Aucun élément trouvé",
    },
    notFoundText: {
      type: String,
    },
    notFoundClick: {
      type: Function,
    },
    notFoundClickText: {
      type: String,
      default: "",
    },
    /* Pour ne pas afficher les cases à cocher dans le cloud en lecture seule */
    bypassSelectable: {
      type: Boolean,
      required: false,
      default: false,
    },
    /* La liste des items à afficher */
    items: {
      type: Array,
      required: true,
    },
    /* La liste des paramètres des items à afficher,
     ** contient une liste d'objets ayant la structure suivante :
     **
     ** {
     **		name: String, <- Le nom du champ à afficher (dans le table-header)
     **		key: String   <- La clé de l'objet du champ à afficher (dans le table-body)
     **		format: Function <- Fonction pour formater le texte à afficher (optionnel)
     **
     **      Pour les icônes :
     **
     **		hasIcon: Boolean  <- Ajout une icône à la colonne (optionnel)
     **		isIcon: Boolean  <- Passe la colonne en mode icône (optionnel)
     **		iconClass: Function <- Renvoie une classe css (requis si isIcon ou hasIcon),
     **						cette classe doit se trouver dans app/styles/general.scss
     **		iconFormat: Function <- Renvoie le nom de l'icône à afficher (requis si isIcon ou hasIcon)
     ** }
     */
    parameters: {
      type: Array,
      required: true,
    },

    hasPagination: {
      type: Boolean,
      required: false,
      default: false,
    },

    pageSize: {
      type: Number,
      default: 10,
    },
  },

  data() {
    return {
      pageCount: 0,
      page: 1,
      dataList: [],
      config,
    };
  },

  computed: {
    ...mapGetters([
      "showSnackbar",
      "androidUrl",
      "iOsUrl",
      "isJEP",
      "isCE",
      "isJEPA",
      "isMEEP",
      "isLCM",
      "isGC",
    ]),
    sortableClass() {
      return {
        "sortable-jep": this.isJEP,
        "sortable-ce": this.isCE,
        "sortable-jepa": this.isJEPA,
        "sortable-meep": this.isMEEP,
        "sortable-lcm": this.isLCM,
        "sortable-gc": this.isGC,
      };
    },
    sortableListTableClass() {
      return {
        "sortable-list__table--jep": this.isJEP,
        "sortable-list__table--ce": this.isCE,
        "sortable-list__table--jepa": this.isJEPA,
      };
    },
  },

  beforeUpdate() {
    // Appear pagination
    if (this.hasPagination) {
      //calcul number of page
      this.pageCount = Math.ceil(this.items.length / this.pageSize);

      let e = this;
      this.items.forEach(function (item, i) {
        e.items[i].isInvisible = !(
          i >= (e.page - 1) * e.pageSize && i < e.page * e.pageSize
        );
      });
    }
  },

  methods: {
    ...mapActions(["CLOSE_SNACKBAR"]),

    bindClass(hasPagination, item) {
      return {
        "md-hidden": hasPagination && item.isInvisible,
        "md-selected": item?.messageError && !this.isMEEP,
        "md-import-error": item?.messageError && this.isMEEP,
        "md-background": item?.messageError,
      };
    },

    async goTo(item, parameter) {
      try {
        // Current item is clickable;
        if (parameter && parameter.clickable) {
          this.$emit("item-click", {
            item,
            parameter,
          });
          return;
        }

        // Do nothing if link not presented
        if (!this.link) {
          return;
        }

        // Go to item
        await this.$router.push(this.link + item.id);
      } catch (err) {
        console.log("Error goTo", err);
      }
    },

    /* i = item(s) */
    onSelect(i) {
      this.$emit("selected", i);
    },

    isDirectory(item) {
      return item.is_directory === 1 || item.is_directory === true;
    },

    getItemValue(item, path) {
      return get(item, path);
    },

    unCheckAll() {
      this.$refs.table.MdTable.selectedItems = [];
    },
  },
};
</script>

<style lang="scss">
@import "../styles/_variable.scss";

#app {
  .sortable-list {
    width: 100%;
    // CSS sortable-list__table in JEPA web
    .sortable-list__table--jepa.md-theme-default.md-table {
      .md-table-content {
        thead {
          th {
            background-color: var(--bg-alternate);
            font-family: var(--font-bold);
            font-size: toRem(12);
            @include for-lg {
              font-size: toRem(20);
            }
          }

          .md-table-head {
            .md-checkbox-container {
              border-color: var(--text-primary, #fff);
            }
          }
        }
      }

      .md-table-row {
        .md-table-cell {
          border-top: none;
          border-bottom: 1px solid var(--bg-alternate);
          background-color: var(--text-primary);
          font-size: toRem(12);
          font-family: var(--font);
          @include for-lg {
            font-size: toRem(20);
          }
        }
      }
    }

    // CSS sortable-list__table in JEP web
    .sortable-list__table--jep.md-theme-default.md-table {
      .md-table-content {
        thead {
          th {
            background-color: var(--bg-alternate);
            font-family: var(--font-bold);
            font-size: toRem(12);
            @include for-lg {
              font-size: toRem(20);
            }
          }

          .md-table-head {
            .md-checkbox-container {
              border-color: var(--text-primary, #fff);
            }
          }
        }
      }

      .md-table-row {
        .md-table-cell {
          border-top: none;
          border-bottom: 1px solid var(--bg-secondary);
          background-color: var(--text-primary);
          font-size: toRem(12);
          font-family: var(--font);
          @include for-lg {
            font-size: toRem(20);
          }
        }
      }
    }

    // CSS sortable-list__table in CE web
    .sortable-list__table--ce.md-theme-default.md-table {
      .md-table-content {
        thead {
          th {
            background-color: var(--bg-alternate);
            font-family: var(--font-bold);
            font-size: toRem(12);
            @include for-lg {
              font-size: toRem(20);
            }
          }

          .md-table-head {
            .md-checkbox-container {
              border-color: var(--bg-third, #000);
            }
          }
        }
      }

      .md-table-row {
        .md-table-cell {
          border-top: none;
          border-bottom: 1px solid var(--bg-secondary);
          background-color: var(--text-primary);
          font-family: var(--font);
          font-size: toRem(12);
          @include for-lg {
            font-size: toRem(20);
          }
        }
      }
    }

    .md-toolbar.md-theme-default {
      background: transparent;
      padding: 0;
      min-height: 0;
      margin-bottom: 20px;

      .md-title {
        white-space: normal;
        font-family: var(--font-bold);
        font-size: 29px;
        margin: 0;
      }
    }

    &__menu {
      &-text {
        font-size: 12px;
        font-weight: bold;
      }
    }

    &__image {
      width: 143px;
      height: 60px;
      object-fit: cover;
      @include for-lg {
        width: 291px;
      }
    }

    &__table.md-theme-default.md-table {
      border-radius: 11px;
      background-color: transparent;
      box-shadow: none;
      .md-table-head:nth-child(2) {
        .md-table-head-label {
          padding-left: 25px;

          .md-table-sortable-icon {
            left: 0;
          }
        }
      }
      .md-table-head {
        height: 56px;
        @include for-lg {
          height: 62px;
        }
      }
      .md-table-row.md-import-error.md-has-selection {
        .md-table-cell {
          background-color: var(--bg-import-error);
        }

        &:hover {
          .md-table-cell {
            background-color: var(--bg-import-hover);
          }
        }
      }

      .md-table-row.md-import-error.md-has-selection.md-selected {
        .md-table-cell {
          background-color: var(--bg-import-selected);
        }
      }
      .md-table-row.md-import-error.md-has-selection.md-selected:hover {
        .md-table-cell {
          background-color: var(--bg-import-hover);
        }
      }

      .md-table-row.md-selected {
        .md-table-cell {
          background-color: var(--bg-secondary);
        }

        .md-checkbox-container {
          border-color: var(--bg-third);
        }
      }

      .md-checkbox-container {
        border-color: var(--text-primary);
        border-radius: 50%;
        border: 2px solid;
        background-color: transparent;
        width: 20px;
        height: 20px;

        &::after {
          height: 13px;
          top: 3px;
          left: 8px;
        }
      }

      .md-table-content {
        width: 100%;
        background-color: transparent;

        thead {
          th {
            background-color: var(--bg-third);
            font-family: var(--font-bold);
            font-size: toRem(12);
            @include for-lg {
              font-size: toRem(20);
            }
          }

          th:first-child {
            border-top-left-radius: 11px;
            border-bottom-left-radius: 11px;
          }

          th:last-child {
            border-top-right-radius: 11px;
            border-bottom-right-radius: 11px;
          }

          .md-table-head {
            color: var(--text-primary);

            svg {
              fill: var(--text-primary);
            }

            .md-checkbox-container {
              border-color: var(--bg-alternate, #fff);
            }
          }
        }

        tbody:before {
          height: 25px;
          width: 100%;
          content: "";
          display: block;
        }

        tbody {
          tr:first-child {
            border-top-left-radius: 10px;

            .md-table-cell:first-child {
              border-top-left-radius: 11px;
            }

            .md-table-cell:last-child {
              border-top-right-radius: 11px;
            }
          }
        }
      }

      .md-table-row {
        cursor: pointer;
        height: 64px;
        @include for-lg {
          height: 94px;
        }

        .md-table-cell {
          border-top: none;
          border-bottom: 1px solid var(--bg-secondary);
          background-color: var(--bg-alternate);
          font-family: var(--font);
          font-size: toRem(12);
          @include for-lg {
            font-size: 20px;
          }

          .app-icon {
            margin-top: 5px;
            width: 20px;
            height: 20px;
            @include for-lg {
              width: 30px;
              height: 30px;
            }

            &-small {
              margin-top: 5px;
              width: 26px;
              height: 26px;
            }

            &-big {
              margin-top: 5px;
              width: 17px;
              height: 17px;
              @include for-lg {
                width: 34px;
                height: 34px;
              }
            }
          }

          .md-button {
            .md-ripple {
              padding: 0;
            }
          }

          .meep-icon-button {
            height: 26px;
            min-height: 26px;
            width: 26px;
            min-width: 26px;
          }

          &.primary-item {
            font-family: var(--font-extrabold);
          }
        }

        &:hover:not(.md-header-row) {
          .md-table-cell,
          .md-table-cell.md-primary--inverted {
            background-color: var(--bg-primary);
            color: var(--text-primary);

            .app-icon {
              path {
                fill: var(--text-primary);
              }
            }
          }
        }
      }
    }

    .md-hidden {
      display: none;
    }

    .md-background {
      .md-table-cell {
        &.md-table-cell-selection {
          .md-checkbox {
            .md-checkbox-container {
              background-color: transparent;
            }
          }

          .md-checkbox.md-checked {
            .md-checkbox-container {
              background-color: var(--bg-third);
            }
          }
        }
      }
    }

    .table-pagination {
      border: 0;
      height: 56px;
      font-size: 12px;
      margin-top: 10px;
      text-align: center;

      .pagination {
        width: 40px;
        height: 40px;

        &.md-table-pagination-previous {
          &::after {
            font-family: "Material Icons";
            content: "\E5CB";
            font-size: 30px;
            position: absolute;
            top: 20%;
            left: 15%;
          }
        }

        &.md-table-pagination-next {
          &::after {
            font-family: "Material Icons";
            content: "\E5CC";
            font-size: 30px;
            position: absolute;
            top: 20%;
            left: 15%;
          }
        }
      }

      .active {
        background-color: var(--bg-primary);
        color: var(--text-primary) !important;
      }
    }
  }

  .sortable-lcm {
    .md-ripple {
      overflow: initial;
      -webkit-mask-image: initial;
    }

    .sortable-list__table.md-theme-default.md-table {
      .md-table-content {
        thead {
          th {
            background-color: var(--bg-third, var(--bg-primary));
            font-family: var(--font-bold);
            font-size: toRem(12);
            @include for-lg {
              font-size: toRem(20);
            }
          }

          .md-table-head {
            color: var(--text-primary);

            svg {
              fill: #fff;
            }

            .md-checkbox-container {
              border-color: var(--text-primary);
            }
          }
        }
      }

      .md-table-row {
        .md-table-cell {
          border-bottom: none;
          background-color: var(--md-table-background);
        }

        &:hover:not(.md-header-row) {
          .md-table-cell,
          .md-table-cell.md-primary--inverted {
            background-color: var(--bg-primary);
            color: var(--text-primary);

            .app-icon {
              path {
                fill: var(--text-primary);
              }
            }
          }
        }
      }

      .md-selected {
        .md-table-cell {
          background-color: var(--bg-secondary);
          color: var(--text-primary);

          svg {
            path {
              fill: var(--text-primary);
            }
          }
        }

        .md-checkbox-container {
          border-color: var(--bg-third);
        }
      }
    }
  }

  .sortable-gc {
    .sortable-list__table.md-theme-default.md-table {
      .md-table-content {
        thead {
          th {
            background-color: var(--bg-third, var(--bg-primary));
            font-family: var(--font-bold);
            font-size: toRem(12);
            @include for-lg {
              font-size: toRem(20);
            }
          }

          .md-table-head {
            color: var(--text-primary);

            svg {
              fill: #fff;
            }

            .md-checkbox-container {
              border-color: var(--text-primary);
            }
          }
        }
      }

      .md-table-row {
        .md-table-cell {
          border-bottom: none;
          background-color: var(--md-table-background);
        }

        &:hover:not(.md-header-row) {
          .md-table-cell,
          .md-table-cell.md-primary--inverted,
          .md-checkbox-container {
            background-color: var(--bg-primary);
            color: var(--text-primary);
            border-color: var(--text-primary);

            .app-icon {
              path {
                fill: var(--text-primary);
              }
            }

            &.cloud-browse__icon {
              svg {
                fill: var(--text-primary);
              }
            }
          }
        }
      }

      .md-selected {
        .md-table-cell {
          background-color: var(--bg-secondary);
          color: var(--text-primary);

          svg {
            path {
              fill: var(--text-primary);
            }
          }
        }

        .md-checkbox-container {
          border-color: var(--text-primary);
        }
      }
    }
  }

  .sortable-jepa {
    .sortable-list__table.md-theme-default.md-table {
      .md-table-row {
        .md-table-cell {
          background-color: var(--md-table-background);
        }

        &:hover:not(.md-header-row) {
          .md-table-cell,
          .md-table-cell {
            background-color: var(--md-table-hover);
            color: var(--bg-primary);

            .app-icon-big {
              path {
                fill: var(--bg-primary);
              }
            }

            .app-icon {
              path {
                fill: var(--bg-primary);
              }
            }
          }
        }
      }

      .md-selected {
        .md-table-cell {
          background-color: var(--bg-secondary);
          color: var(--bg-primary);

          svg {
            path {
              fill: var(--bg-primary);
            }
          }
        }

        .md-checkbox-container {
          border-color: var(--bg-third);
        }
      }
    }
  }
}

#app {
  .sortable-ce {
    .sortable-list__table.md-theme-default.md-table {
      .md-table-content {
        thead {
          th {
            background-color: var(--bg-third, var(--bg-primary));
            font-family: var(--font-bold);
            font-size: toRem(12);
            @include for-lg {
              font-size: toRem(20);
            }
          }

          .md-table-head {
            color: var(--text-primary);

            svg {
              fill: #fff;
            }

            .md-checkbox-container {
              border-color: var(--text-primary);
            }
          }
        }
      }

      .md-table-row {
        .md-table-cell {
          border-bottom: none;
          background-color: var(--md-table-background);
        }

        &:hover:not(.md-header-row) {
          .md-table-cell,
          .md-table-cell.md-primary--inverted {
            background-color: var(--md-table-hover);
            color: var(--text-primary);

            .app-icon {
              path {
                fill: var(--text-primary);
              }
            }
          }
        }
      }

      .md-selected {
        .md-table-cell {
          background-color: var(--md-table-selected);
          color: var(--text-primary);

          svg {
            path {
              fill: var(--text-primary);
            }
          }
        }

        .md-checkbox-container {
          border-color: var(--bg-third);
        }
      }
    }
  }
}
</style>
