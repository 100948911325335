import User from "../model/users";
import { USERS } from "../constants";
import Login from "../model/login";
import Notifs from "../model/notifications";
import Chat from "../model/chat";
import Vue from "vue";
import newMessageSoundFile from "../assets/audio/chat_new_message.mp3";
import fetch from "../services/fetch";
import { themeOptions } from "../themes";
import localConfig from "../../site-config.json";
import router from "../router";
import filesModel from "../model/files";

const NUMBER_MESSAGES = 10;

export const SIGNIN = async ({ commit, dispatch, state }, form) => {
  try {
    const data = await Login.signin(form);

    if (data.user.scope === USERS.SUPERUSER) {
      throw new Error("Vous n'avez pas les droits nécessaires");
    }

    data.isSuperUser = false;

    commit("LOGIN_SUCCESS", data);

    try {
      if (state.socketId && !state.socketSynced) {
        await dispatch("SYNC_SOCKET");
      }

      dispatch("GET_NOTIFICATIONS", true);
    } catch (socketError) {
      console.log("Socket sync failed", socketError);
    }

    return data;
  } catch (err) {
    commit("LOGIN_ERROR");
    throw err;
  }
};

export const SIGNIN_ADMIN = ({ commit }, data) => {
  return Login.signin_admin(data)
    .then(data => {
      if (data.user.scope !== USERS.SUPERUSER) {
        throw new Error("Vous n'avez pas les droits nécessaires");
      }

      data.isSuperUser = true;

      commit("LOGIN_SUCCESS", data);

      return data;
    })
    .catch(err => {
      commit("LOGIN_ERROR");
      throw err;
    });
};

export const VERIFY = async (
  { commit, dispatch, state },
  { jwtToken, isSuperUser }
) => {
  try {
    commit("LOGIN_IN_PROGRESS");

    const data = await Login.verify(jwtToken, isSuperUser);

    commit("LOGIN_SUCCESS", {
      user: data.user,
      token: jwtToken,
      isSuperUser,
    });

    try {
      if (state.socketId && !state.socketSynced) {
        await dispatch("SYNC_SOCKET");
      }

      dispatch("GET_NOTIFICATIONS", true);
    } catch (socketError) {
      console.log("Socket sync failed", socketError);
    }
  } catch (err) {
    console.log("error");
    commit("LOGIN_ERROR");

    throw err;
  }
};

export const UPDATE_SELF = ({ commit }, self) => {
  return User.update(self).then(() => {
    commit("UPDATE_SELF", self);
  });
};

export const GET_NOTIFICATIONS = async ({ commit, state, getters }, isInit) => {
  if (getters.isSuperUser) {
    return;
  }

  const notifs = await Notifs.getAll(state.notifications.length > 0);
  const currentNotifications = state.notifications;

  if (!isInit) {
    const newNotifications = notifs.filter(notification => {
      return !currentNotifications.find(
        oldNotif => oldNotif.id === notification.id
      );
    });

    newNotifications.forEach(notification => {
      Vue.toasted.show(notification.text, {
        theme: "toasted-primary",
        position: "top-center",
        type: "error",
        duration: 5000,
        action: [
          {
            text: "Fermer",
            onClick: (e, toastObject) => {
              toastObject.goAway(0);
            },
          },
          {
            text: "Voir",
            onClick: (e, toastObject) => {
              router.push(notification.url);
              toastObject.goAway(0);
            },
          },
        ],
      });
    });
  }

  commit("SET_NOTIFICATIONS", notifs);
};

export const READ_NOTIFICATION = ({ commit }, notifId) => {
  Notifs.read(notifId).then(() => {
    commit("READ_NOTIFICATION", notifId);
  });
};

export const READ_ALL_NOTIFICATIONS = ({ commit }, readChatOnly) => {
  Notifs.readAll(readChatOnly);
  commit("READ_ALL_NOTIFICATIONS", readChatOnly);
};

export const REMOVE_NOTIFICATION = ({ commit }, notifId) => {
  Notifs.remove(notifId).then(() => {
    commit("REMOVE_NOTIFICATION", notifId);
  });
};

export const REMOVE_ALL_NOTIFICATIONS = ({ commit }, readChatOnly) => {
  Notifs.removeAll(readChatOnly).then(() => {
    commit("REMOVE_ALL_NOTIFICATIONS", readChatOnly);
  });
};

export const GET_NUMBER_OF_UNREAD_MESSAGES = ({ commit }) => {
  Chat.unreadCount().then(res => {
    commit("SET_UNREAD_MESSAGES_COUNT", res.sum);
  });
};

export const SELECT_ORGANIZATION = ({ commit, state }, data) => {
  return User.selectOrganization(state.self.email, data).then(data => {
    commit("SELECT_ORGANIZATION", data);
    return data;
  });
};

export const SHOW_CHAT = async (
  { commit, state },
  { company_id, unreadOnly }
) => {
  const messages = await Chat.getMessages(
    company_id,
    unreadOnly,
    NUMBER_MESSAGES
  );

  commit("SET_LOAD_MESSAGE_MORE", messages.length === NUMBER_MESSAGES);
  commit("TOGGLE_SHOW_CHAT", { company_id, messages });
  commit("REMOVE_NOTIFICATION_CHAT", company_id);

  if (!state.isMobile || state.currentChatId === company_id) {
    Chat.readAll(company_id);
  }
};

export const LOAD_MORE_CHAT = async ({ state, commit }) => {
  try {
    const lastDate = state.messages.key[0];
    const messages = await Chat.getMessages(
      state.currentChatId,
      0,
      NUMBER_MESSAGES,
      null,
      state.messages.data[lastDate][0].created_at
    );
    if (messages.length !== 0) {
      commit("ADD_MESSAGES", messages);

      commit("SET_LOAD_MESSAGE_MORE", messages.length === NUMBER_MESSAGES);
    } else {
      commit("SET_LOAD_MESSAGE_MORE", false);
    }
    return messages.length;
  } catch (err) {
    this.$toasted.global.AppError({
      message: err.msg,
    });
    console.log(err);
  }
};

export const SHOW_CONVERSATIONS = async ({ commit }) => {
  try {
    const conversations = await Chat.getConversations();

    commit("TOGGLE_SHOW_CONVERSATION", {
      conversations,
      is_show: true,
    });
  } catch (err) {
    console.log("Get conversations error", err);
    throw err;
  }
};

export const CHECK_CLOUD_EMPLOYEE = async ({ commit, getters }) => {
  try {
    if (getters.isEmployee) {
      const cloudItems = await filesModel.getRoot();

      commit("SHOW_CLOUD_EMPLOYEE", !!cloudItems.length);
    }
  } catch (err) {
    console.log("Get cloud error", err);
    Vue.toasted.global.AppError({
      message: err.msg,
    });
  }
};

export const SYNC_SOCKET = async ({ commit, state, getters }) => {
  // Only sync socket when userStats has logged in

  if (state.self && state.self.id && !getters.isSuperUser) {
    console.log("Sync socket");
    await User.socketSync(state.socketId);
    commit("SOCKET_SYNCED");
  }
};

export const DESYNC_SOCKET = async ({ commit, state }) => {
  await User.socketDeSync(state.socketId);
  commit("SOCKET_DESYNCED");
};

// Socket Actions
export const SOCKET_ID = ({ commit, state, dispatch }, id) => {
  commit("SET_SOCKET_ID", id);

  if (state.isAuth) {
    dispatch("SYNC_SOCKET");
  }
};

export const SOCKET_NOTIFICATION_CHANGE = ({ dispatch }) => {
  dispatch("GET_NOTIFICATIONS");
};

export const SOCKET_CHAT_COUNT_CHANGE = (
  { state, commit, dispatch },
  message
) => {
  if (state.currentChatId === message.company_id) {
    commit("ADD_MESSAGE", message);

    if (!message.seen_by.includes(state.self)) {
      Chat.readAll(message.company_id);
    }
  } else {
    // Notify userStats
    dispatch("GET_NOTIFICATIONS");
    const sound = new Audio(newMessageSoundFile);
    sound.play();
  }

  // Update unread count
  dispatch("SHOW_CONVERSATIONS");
};

export const SOCKET_CHAT_SEEN_CHANGE = (
  { state, commit, dispatch },
  { messages, company_id }
) => {
  if (state.currentChatId === company_id) {
    commit("UPDATE_MESSAGES", messages);

    if (!state.isMobile) {
      // Update unread count
      dispatch("SHOW_CONVERSATIONS");
      dispatch("GET_NOTIFICATIONS");
    }
  }
};
export const CLOSE_SNACKBAR = ({ commit }) => {
  commit("UPDATE_SHOW_SNACKBAR", false);
};

export const FETCH_CONFIG = async ({ commit }) => {
  let siteConfig = {
    name: "Meep",
    color_scheme: 22,
  };

  commit("SET_SITE_NAME", siteConfig.name);

  let theme = themeOptions.find(theme => theme.id === siteConfig.color_scheme);

  // Applying theme to body
  let bodyStyles = document.body.style;

  Object.keys(theme).forEach(property => {
    if (property === "name" || property === "id") {
      return;
    }

    bodyStyles.setProperty(property, theme[property]);
  });

  if (process.env.NODE_ENV !== "production") {
    console.log("Local config", process.env.VUE_APP_LOCAL_CONFIG);
  }

  if (process.env.VUE_APP_LOCAL_CONFIG) {
    siteConfig = localConfig;
  } else {
    /**
     * @type {{name: string, id: string, color_scheme: number, logo: string}} siteConfig
     */
    siteConfig = await fetch("api/config", {
      method: "get",
    });
  }

  commit("SET_COLOR_SCHEME", siteConfig.color_scheme);
  commit("SET_LOGO", siteConfig.logo);
  commit("SET_LOGO_MINIFIED", siteConfig.logoMinified);
  commit("SET_ORGANIZATION_ID", siteConfig.id);
  commit("SET_SITE_NAME", siteConfig.name);
  commit("SET_ANDROID_URL", siteConfig.androidUrl);
  commit("SET_IOS_URL", siteConfig.iOsUrl);

  document.title = siteConfig.name;

  theme = themeOptions.find(theme => theme.id === siteConfig.color_scheme);

  // Applying theme to body
  bodyStyles = document.body.style;

  Object.keys(theme).forEach(property => {
    if (property === "name" || property === "id") {
      return;
    }

    bodyStyles.setProperty(property, theme[property]);
  });
};

export const SET_IMPORT_COMPANIES = async ({ commit }, data) => {
  commit("SET_IMPORT_COMPANIES", data);
};

export const SET_IMPORT_USERS = async ({ commit }, data) => {
  commit("SET_IMPORT_USERS", data);
};

export const SET_IMPORT_ERROR_LINE = async ({ commit }, data) => {
  commit("SET_IMPORT_ERROR_LINE", data);
};
